import { h } from 'hyperapp';

export default ({ route }) => ({ selectedRoute }, { createMap }) => {
  if (selectedRoute
    && route.id === selectedRoute.id
    && route.data.route_xml !== '') {
    return (
      <div
        class='map'
        id='map-holder'
        oncreate={e => createMap({
          mapid: e.id,
          gpx: route.data.route_xml,
          markers: route.data.route_pois,
          icons: { start: route.data.icon_start, end: route.data.icon_end },
          stops: { start: route.data.stops.start, end: route.data.stops.end },
          waypoints: route.data.bullet_points,
        })}
      >
      </div>
    );
  }

  return null;
};
