import utils from '../views/content/utils';

export default {
  timestamp: value => ({ timestamp: utils.formatTime2unix(value) }),
  filters: target => ({ filters }) => {
    const types = filters.split(',')
      .filter(e => e.length > 0 && e !== target.value);

    if (target.checked) {
      types.push(target.value);
    }

    return { filters: types.join(',') };
  },
};
