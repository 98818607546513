import { location } from '@hyperapp/router';

import {
  search,
  searchCounter,
  setAjaxLoading,
  setRoutes,
  emptyResults,
  setFromSingleUrl,
} from './search';

import {
  createMap,
  drawMap,
  initMap,
} from './map';

import pagination from './pagination';
import params from './params';

import {
  getRoute,
  selectRoute,
  getRoutePlan,
  routePlanHandler,
  setTransport,
  nextRoute,
  prevRoute,
} from './route';

import {
  setAddresses,
  setAddressListOpen,
  setSelectedAddress,
  selectAddress,
  searchAddress,
  searchAddressOnFocus,
} from './addresses';

import {
  getSomeImages,
  setSomeImages,
} from './socials';

import {
  toggleDropDown,
  setDropDownToggle,
} from './dropdown';

import {
  hideModal,
  showModal,
  hideGiftCard,
  showGiftCard,
} from './modal';

import {
  setLanguage,
  loadLanguage,
} from './language';

export default {
  location: location.actions,

  search,
  searchCounter,
  setAjaxLoading,
  setRoutes,
  emptyResults,
  setFromSingleUrl,

  nextRoute,
  prevRoute,

  createMap,
  drawMap,
  initMap,

  pagination,
  params,

  getRoute,
  selectRoute,
  getRoutePlan,
  routePlanHandler,
  setTransport,

  setAddresses,
  setAddressListOpen,
  setSelectedAddress,
  selectAddress,
  searchAddress,
  searchAddressOnFocus,

  getSomeImages,
  setSomeImages,

  toggleDropDown,
  setDropDownToggle,

  hideModal,
  showModal,
  hideGiftCard,
  showGiftCard,

  setLanguage,
  loadLanguage,
};
