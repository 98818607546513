import 'babel-polyfill';
import { app } from 'hyperapp';
import { location } from '@hyperapp/router';

import actions from './actions';
import state from './state';
import view from './views';

// IE hack; even with polyfill, devtools somehow breaks IE
const devtools = null;

// const devtools = process.env.NODE_ENV === 'production'
//  ? null
//  : require('hyperapp-redux-devtools');

const appArgs = [
  state,
  actions,
  view,
  document.body,
];

if (devtools) {
  devtools(app)(...appArgs);
} else {
  const main = app(...appArgs);
  location.subscribe(main.location);
}
