import { h } from 'hyperapp';
import { Route, Switch } from '@hyperapp/router';

import Header from './header';
import Content from './content';
import Footer from './footer';
import GPXModal from './modal';
// import GiftCardModal from './gift-card-modal';

const Index = () => () => (
  <div
  // oncreate={() => setTimeout(() => {
  //   actions.showGiftCard();
  // }, 7000)}
  >
    <Header />
    <Content />
    <Footer />
    <GPXModal />
    {/* <GiftCardModal /> */}
  </div>
);

const Single = () => (state, actions) => {
  const paths = state.location.pathname.substr(1).split('/');

  if (!state.fromSingleUrl || (paths.length > 1 && !paths[1])) {
    return (<Index />);
  }

  if (state.selectedRoute) {
    if (paths.length > 1 && paths[1] === state.selectedRoute.slug[state.language]) {
      return (<Index />);
    }
  }

  return (
    <div oncreate={actions.search}></div>
  );
};

export default () => () => (
  <div class='container'
  // oncreate={() => setTimeout(() => {
  //     const mouseout = (evt) => {
  //       if (evt.toElement === null && evt.relatedTarget === null) {
  //         actions.showGiftCard();
  //         document.removeEventListener('mouseout', mouseout);
  //       }
  //     };
  //      document.addEventListener('mouseout', mouseout);
  //   }, 4500)
  // }
  >
    <Switch>
      <Route render={Index} path=':language/' />
      <Route render={Single} />
    </Switch>
  </div>
);
