import { h } from 'hyperapp';

import Routes from './routes';
import Route from './route';
import Search from './search';
import Partners from './partners';

import t from '../../utils/translate';

export default () => (
  <div>
    <MainSearch />
    <Routes />
    <Route />
    <Disclaimer />
    <Partners />
  </div>
);

const MainSearch = () => ({ routes, language }) => (
  <section class={`main-search ${routes.length < 1 ? 'full-height' : 'hide'}`}>
    <div class='search-container'>
      <div class='search-title hero-content'>
        <h3>{t('Julkisella liikenteellä Nuuksion reiteille', language)}</h3>
      </div>
      <div class='search-paragraph hero-content'>
        <span>
          {t('Löydä kotioveltasi julkisella liikenteellä saavutettavia reittivaihtoehtoja matkan varrella sijaitsevine palveluineen.', language)}
        </span>
      </div>
      <Search />
    </div>
    <CustomDropdownBg />
    <div class='img-copyright'>
      {t('kuva', language)}: <a target='_blank' href='https://flic.kr/p/gU8Mrj'>Outdoors Finland</a> / <a target='_blank' href='https://creativecommons.org/licenses/by/2.0/'>CC BY 2.0</a>
    </div>
  </section>
);

const CustomDropdownBg = () => ({ dropdownOpen }, actions) => {
  if (!dropdownOpen) {
    return null;
  }

  return (
    <div class='select-custom-overlay' onclick={e => actions.toggleDropDown(e.target)}></div>
  );
};

const Disclaimer = () => ({ language }) => (
  <section class='disclaimer'>
    <h4>{t('Tietoja Nuuksioon.fi -palvelusta', language)}</h4>
    <p>
      {t('Nuuksioon.fi -palvelukokeilu on osa Perille asti -hankkeen Nuuksion saavutettavuuden kehittämistoimia.', language)}
    </p>

    <p>
      {t('Perille Asti -hankkeen tavoitteena on parantaa matkailijoiden, työntekijöiden ja asukkaiden liikkumista pääkaupunkiseudulla täydentäen nykyistä liikennejärjestelmää. Espoossa saavutettavuutta parannetaan Nuuksion ja Rantaraitin näkökulmista. Hanketta toteuttaa Espoossa Espoo Marketing Oy.', language)}
    </p>

    <p>
      {t('Perille asti -hanke saa rahoituksensa Euroopan aluekehitysrahastosta osana 6Aika -strategiaa vuosina 2017-2019. Hankkeen toteuttavat Espoo Marketing Oy, Forum Virium Helsinki, Vantaan kaupunki, Aalto yliopisto, Metropolia ammattikorkeakoulu ja Demos Helsinki.', language)}
    </p>
  </section>
);
