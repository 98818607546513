import { h } from 'hyperapp';
import FeedbackBtn from './feedback-btn';

import ZoneatlasNote from '../../images/zoneatlas-footer-note.png';

export default () => () => (
  <footer>
    <div class='container'>
      <div>
        <a class='footer-logo' href='https://zoneatlas.com' target='_blank' rel='noopener'>
          <img alt='Made with Zoneatlas interactive map solutions' src={ZoneatlasNote} />
        </a>
      </div>
      <div>

      </div>
      <div class='social-btns'>
        {/* <ul>
          <li><a href='#'>So</a></li>
          <li><a href='#'>Me</a></li>
        </ul> */}
      </div>
      <FeedbackBtn />
    </div>
  </footer>
);
