import { h } from 'hyperapp';
import flatpickr from 'flatpickr';
import { Finnish } from 'flatpickr/dist/l10n/fi';

import t from '../../utils/translate';

const travelerTypes = [
  { id: 'quick_visit', label: 'Pikapistäytyjä' },
  { id: 'for_fun', label: 'Virkistäytyjä' },
  { id: 'hiking', label: 'Vaeltaja' },
  { id: 'children_friendly', label: 'Lasten kanssa' },
  { id: 'jogging', label: 'Luonnossa lenkkeilijä' },
  { id: 'berries', label: 'Sienestäjä / marjastaja' },
  { id: 'biking', label: 'Pyöräilijä' },
];

const initDatePicker = (element, language) => {
  flatpickr(element, {
    enableTime: true,
    locale: language === 'fi' ? Finnish : null,
    time_24hr: true,
    defaultHour: 9,
    defaultMinute: 0,
    minDate: 'today',
    altInput: true,
    altFormat: 'd.m.Y H:i',
  });
};

const translateType = (filters, language) => {
  if (!filters) {
    return t('Valitse, millainen retkeilijä olet', language);
  }

  const types = filters.split(',')
    .map(filter => travelerTypes
      .find(({ id }) => id === filter).label);

  return types.map(label => t(label, language)).join(', ');
};

export default () => (
  {
    params,
    addresses,
    ajaxLoading,
    language,
  },
  {
    search,
    searchAddress,
    searchAddressOnFocus,
  },
) => (
  <div class={`routes-search ${ajaxLoading ? 'ajax-loading' : ''}`}>
    <div class='input-group'>
      <CustomDropDown id='type_select' title={translateType(params.filters, language)} />
    </div>
    <DateTimeBlock />
    <div class='input-group'>
      <div>
        <label for='address'>{t('Lähtöosoite', language)}</label>
      </div>
      <div class='address-search'>
        <input
          id='address-input'
          onfocus={e => searchAddressOnFocus(e.target)}
          onkeydown={e => searchAddress(e.target)}
          placeholder={t('Syötä lähtöosoite pk-seudulla', language)}
          type='text' />
        <AddressesList addresses={addresses} />
      </div>
    </div>
    <div class='input-group'>
      <div>
        <a class='button' onclick={search} href='#'>
          {ajaxLoading ? t('Ladataan...', language) : t('Näytä reittivaihtoehdot', language)}
        </a>
      </div>
    </div>
  </div>
);


const DateTimeBlock = () => ({ language }, { params }) => (
  <div class='input-group'>
    <div>
      <label for='date'>{t('Retken lähtöajankohta', language)}</label>
    </div>
    <div class='datetime-input'>
      <input
        id='date'
        placeholder={t('Milloin haluat lähteä?', language)}
        oncreate={e => initDatePicker(e, language)}
        onchange={e => params.timestamp(e.target.value)}
        />
    </div>
  </div>
);

const AddressesList = ({ addresses }) => ({ addressListOpen }) => {
  if (!addresses.length) {
    return null;
  }

  return (
    <div class={`autocomplete-list ${addressListOpen ? 'active' : 'hidden'}`}>
      {addresses.map(address => (
        <AddressBlock address={address} />
        ))}
    </div>
  );
};

const AddressBlock = ({ address }) => ({ selectedAddress }, { selectAddress }) => (
  <div
  class={`address-item ${selectedAddress && selectedAddress.name === address.name ? 'active' : ''}`}
  onclick={() => selectAddress(address)}
  >
    {address.name}
  </div>
);

const CheckBoxListItem = ({ id, label }) => (state, { params }) => {
  const text = t(label, state.language);

  // Dirty fix: don't show mushroom item in english page
  if (text === 'Mushroom / berry picking') {
    return null;
  }

  return (
    <li>
      <label for={id}>
        <input type='checkbox' id={id}
          value={id}
          onchange={e => params.filters(e.target)}
          />
        <span class={`icon ${id}`}> </span> {text}
      </label>
    </li>
  );
};

const CustomDropDown = ({ id, title }) => ({ dropdownOpen }, { toggleDropDown }) => (
  <div id={id} class='custom-dropdown-select'>
    <span id='custom-dropdown-toggle' class={`title-dropdown-placeholder toggle-dropdown ${dropdownOpen ? 'select-active' : ''}`} onclick={e => toggleDropDown(e.target)}>{title}</span>
    <div class='title-dropdown-options'>
      <ul>
        {travelerTypes.map(type => (
          <CheckBoxListItem {...type} />
        ))}
      </ul>
    </div>
  </div>
);
