import { h } from 'hyperapp';
import utils from './utils';

import t from '../../utils/translate';

export default () => ({ selectedRoute, routes, language }) => {
  if (!selectedRoute || !routes) {
    return null;
  }

  const {
    name,
    description,
    difficulty,
    duration,
    length,
    data,
  } = selectedRoute;

  const isBiking = selectedRoute.is_biking_trail;

  const setTitle = () => {
    document.title = `Nuuksioon.fi - ${name[language]}`;
  };

  const removeTitle = () => {
    document.title = 'Nuuksioon.fi';
  };

  return (
    <section class='selected-route' onupdate={() => setTitle()} ondestroy={() => removeTitle()}>
      <RouteGPXLink name={name} link={data.route_file_url} xml={data.route_xml}/>
      <div class='route-info'>
        <div class='name'>
          <h2>{name}</h2>
        </div>
        <div class='duration'>
          <span class='icon time'></span> {duration} h
        </div>
        <div class='length'>
          <span class={isBiking ? 'icon bicycle' : 'icon walk'}></span> {length} km
        </div>
        <div class='difficulty'>
          <span title={difficulty} class={`icon ball-icon ${difficulty}`}></span> {t(utils.translateDifficulty(difficulty), language)}
        </div>
      </div>
      <div class='markdown-render' innerHTML={utils.parseMarkdown(description[language])} class='route-description'>
      </div>
      <div class='route-content'>
        <PoisItem pois={data.route_pois} />
        <PlanItem />
      </div>
      <ImagesItem images={data.images} />
    </section>
  );
};

const ImagesItem = ({ images }) => {
  if (images.length < 1) {
    return null;
  }
  return (
    <div class='images'>
      {images.map(src => (
        <div class='route-image'>
          <img src={src} />
        </div>
      ))}
    </div>
  );
};

const WaypointImage = ({ image }) => {
  if (!image) {
    return null;
  }
  return (
    <div class='waypoint-image'>
      <img src={image} />
    </div>
  );
};

const poiHover = ({ e, i, poi }) => {
  const { lat, lng } = poi.coordinates;
  if (lat === '' || lng === '') {
    return;
  }

  const popup = document
    .getElementsByClassName(`leaflet-popup marker-${i}`);

  e.addEventListener('mouseover', () => {
    if (popup[0]) {
      popup[0].classList.add('hilight');
    }
  });

  e.addEventListener('click', () => {
    if (popup[0]) {
      popup[0].classList.add('hilight');
    }
  });

  e.addEventListener('mouseout', () => {
    if (popup[0]) {
      popup[0].classList.remove('hilight');
    }
  });
};

const PoisItem = ({ pois }) => (state) => {
  if (!pois || pois.length < 1) {
    return null;
  }

  return (
    <div class='route-pois'>
      <h4>{t('Palvelut ja nähtävää', state.language)}</h4>
      <ul>
        {pois.map((poi, i) => (
          <li
            class={`poi ${poi.type}`}
            onupdate={e => poiHover({ e, i, poi })}
          >
            <h4>{poi.name[state.language]}</h4>
            <p class='services' >{utils.translateServiceType(poi.services.join(','), state.language)}</p>
            <div class='markdown-render' innerHTML={utils.parseMarkdown(poi.description[state.language])}></div>
            <div class='poi-images'>
              {poi.images.map(image => (
                <a class='poi-image-link' href={image} target='_blank'><img src={image} /></a>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const PlanItem = () => ({ selectedRoute, language }) => {
  const {
    duration,
    data,
    transport,
  } = selectedRoute;

  if (!data.stops || data.stops.length < 1) {
    return null;
  }

  const { start, end } = data.stops;
  const points = data.bullet_points;

  if (start.name === '' || end.name === '') {
    return null;
  }

  let divClass = 'route-plan';
  if (!transport) {
    divClass += ' no-transport';
  } else if (transport === 'error') {
    divClass += ' transport-error';
  }

  return (
    <div class={divClass}>
      <h4>{t('Reittisuunnitelma', language)} <span class='loader'>{t('Haetaan julkisen liikenteen yhteyksiä...', language)}</span></h4>
      <ol>
        <TransportArriveItem start={start} transport={transport} />
        {points.map(point => (
          <li class='plan-part'>
            <div>
              {point.text[language]}
              <WaypointImage image={point.image} />
            </div>
          </li>
        ))}
        <TransportDepartureItem end={end} duration={duration} transport={transport} />
      </ol>
      <div>
        {t('Reitin arvioitu kesto normaalivauhdilla', language)} {duration} {t('tuntia', language)}.
        {t('Otathan huomioon, että luonnossa saattaa lisäksi vierähtää aikaa tauoille ja maisemapysähdyksille.', language)}
      </div>
    </div>
  );
};

const TransportArriveItem = ({ start, transport }) => ({ language }) => {
  if (!transport || typeof transport.arrive === 'undefined') {
    return (
      <li class='plan-part'>
        <div>
          {t('Saavu julkisilla pysäkille', language)} <span class='stop'>{start.name}</span>.
        </div>
      </li>
    );
  }

  return (
    <li class='plan-part'>
      <div>
        {t('Saavu julkisilla pysäkille', language)} <span class='stop'>{start.name}</span>.
      </div>
      <div>
        {t('Lähde liikkeelle klo', language)} {utils.formatTime(transport.arrive.plan.startTime)},
        {t('niin olet perillä klo', language)} {utils.formatTime(transport.arrive.plan.endTime)}
      </div>
      <div>
        <a target='_blank' href={transport.arrive.url}>
          {t('Katso yhteydet osoitteestasi HSL-reittioppaasta.', language)}
        </a>
      </div>
    </li>
  );
};

const DepBlock = ({ time }) => ({ language }) => {
  if (!time) {
    return null;
  }
  return (
    <div>
      {t('Busseja lähtee esim.', language)} {time}
    </div>
  );
};

const TransportDepartureItem = ({ transport, end, duration }) => ({
  reittiopasUrl,
  fromLat,
  fromLng,
  language,
}) => {
  if (duration >= 5) {
    return (
      <li class='plan-part'>
        <div>
          {t('Reitin arvioitu kesto on', language)} {duration} {t('tuntia', language)}.
          {t('Kesto riippuu paljon etenemisnopeudestasi, ja saattaa vaatia yöpymisen puistossa.', language)}
          {t('Varmista paluuyhteydet', language)} <a target='_blank' href={`${reittiopasUrl}Lähtöpaikka::${end.lat},${end.lng}/Määränpää::${fromLat},${fromLng}`}>{t('HSL reittioppaasta', language)}</a>.
        </div>
      </li>
    );
  }

  if (!transport || typeof transport.departure === 'undefined') {
    return (
      <li class='plan-part'>
        <div>
          {t('Reitti päättyy pysäkille', language)} <span class='stop'>{end.name}</span>.
        </div>
      </li>
    );
  }

  const depTime = utils.formatTime(transport.departure.plan.startTime);

  return (
    <li class='plan-part'>
      <div>
        {t('Reitti päättyy pysäkille', language)} <span class='stop'>{end.name}</span>.
      </div>
      <div>
        <a target='_blank' href={transport.departure.url}>
          {t('Katso yhteydet osoitteeseesi HSL-reittioppaasta.', language)}
        </a>
      </div>
      <DepBlock time={depTime}/>
    </li>
  );
};

const RouteGPXLink = ({ name, link, xml }) => ({ language }, { showModal }) => {
  if (!link) {
    const fileName = `${name}.gpx`;
    return (
      <div class='route-gpx-link'>
        <a download={fileName} target='_blank' href={`data:text/xml;charset=utf8,${encodeURIComponent(xml)}`}>{t('Lataa GPX-tiedosto', language)}</a>
        <a href='#' onclick={e => showModal(e)}>{t('Ohjeet GPX tiedoston käyttöön', language)} &raquo;</a>
      </div>
    );
  }

  return (
    <div class='route-gpx-link'>
      <a target='_blank' href={link}>{t('Lataa GPX-tiedosto', language)}</a>
      <a href='#' onclick={e => showModal(e)}>{t('Ohjeet GPX tiedoston käyttöön', language)} &raquo;</a>
    </div>
  );
};
