import { h } from 'hyperapp';

import LogoEU from '../../images/logos/logo_EU_white.png';
import LogoEUEN from '../../images/logos/logo_EU_white_en.png';
import LogoVoimaa from '../../images/logos/logo_VipuVoimaa_white.png';
import LogoVoimaaEN from '../../images/logos/logo_VipuVoimaa_white_en.png';
import LogoLiitto from '../../images/logos/logo_UudenmaanLiitto_white.png';
import LogoLiittoEN from '../../images/logos/logo_UudenmaanLiitto_white_en.png';

import t from '../utils/translate';

export const LanguageSelector = () => ({ language }, { emptyResults }) => {
  const languages = ['fi', 'en'];
  return (
    <div class='language-selector'>
      <ul>
        {languages.map(lang => (<li onclick={() => { emptyResults(); window.location.href = `/${lang}`; }} class={language === lang ? 'active' : ''}>{lang}</li>))}
      </ul>
    </div>
  );
};

export default () => ({ ajaxLoading, routes, language }) => (
  <header class={`main-header ${ajaxLoading ? 'ajax-loading' : ''}`}>
    <h1 class={routes.length > 0 ? 'routes-visible' : ''}>Nuuksioon.fi<LanguageSelector /></h1>
    <section class='hero'>
      {/* <div>
        <h2>Löydä reittisi Nuuksiossa</h2>
      </div>
      <div class='intent'>
        <p>
            Löydä reittivaihtoehtoja sekä Nuuksion kohteita,
            jotka ovat saavutettavissa julkisilla liikennevälineillä
            kotioveltasi.
        </p>
      </div> */}
    </section>
    <section class={routes.length > 0 ? 'routes-visible main-partners' : 'main-partners'}>
      <h4>{t('Yhteistyössä', language)}</h4>
      <img src={language === 'en' ? LogoEUEN : LogoEU } style={{ height: '70px', marginTop: '0px' }}/>
      <img src={language === 'en' ? LogoVoimaaEN : LogoVoimaa } style={{ height: '48px', marginTop: '0px' }}/>
      <img src={language === 'en' ? LogoLiittoEN : LogoLiitto } style={{ height: '54px', marginTop: '0px' }}/>
    </section>
  </header>
);
