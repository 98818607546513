import { h } from 'hyperapp';

import t from '../utils/translate';

const modalId = 'gpx-modal';

export default () => ({ modalDisplay, language }, { hideModal }) => (
  <section
    class='modal'
    id={modalId}
    style={{ display: modalDisplay }}
    onclick={(e) => { // Hide with outside of modal click
      if (e.target.id === modalId) {
        hideModal();
      }
    }}
    >
    <div class='modal-content'>
      <span class='close' onclick={() => hideModal()}>&times;</span>
      <h3>{t('GPX käyttöohje', language)}</h3>
      <h4>{t('Nuuksioon.fi reitit ladattavissa GPX -tiedostoina', language)}</h4>

      <p>{t('GPX on yleinen avoin formaatti GPS-datan siirtämiseen, jonka avulla pystyy siirtämään reittitietoja sovelluksesta toiseen. Nuuksioon.fi reitit ovat ladattavissa GPX-muotoisina, ja ne voi ladata matkapuhelimella erilliseen sovellukseen.', language)}</p>

      <p>{t('Nuuksioon.fi -palvelulla ei ole kaupallisesti tekemistä tässä ohjeessa suositeltujen GPX-sovellusten kanssa, vaan olemme pyrkineet löytämään helpoimmat ratkaisut Androidille ja iOSille.', language)}</p>
      <p><strong>{t('Huom!', language)}<br />{t('Lataa sovellus, jolla avaat tiedostoja, ennen GPX-reittitiedostojen lataamista.', language)}</strong></p>
      <h4>Android</h4>
      {t('Ilmainen vaihtoehto', language)}
      <ol>
        <li><a target='_blank' href='https://play.google.com/store/apps/details?id=com.vecturagames.android.app.gpxviewer'>{t('Lataa sovelluskaupasta sovellus nimeltä GPX Viewer', language)} &raquo;</a></li>
        <li>{t('Paina pitkään Nuuksioon.fi -palvelun “Lataa GPX-tiedosto” linkin päällä ja valitse “Tallenna linkki”', language)}</li>
        <li>{t('Tallenna tiedosto käyttöjärjestelmän ohjeen mukaisesti', language)}</li>
        <li>{t('Tiedoston ladattua avaa se heti linkistä “Avaa tiedosto”', language)}</li>
        <li>{t('Valitse avaavaksi sovellukseksi “GPX viewer”', language)}</li>
      </ol>
      {t('Maksullinen vaihtoehto maastokartoilla', language)}
      <ol>
        <li>{t('Lataa sovelluskaupasta sovellus nimeltä Maastokartat', language)}</li>
        <li>{t('Avaa sovellus', language)}</li>
        <li>{t('Lataa reitti Nuuksioon.fi -palvelusta ja valitse se avattavaksi Maastokartat -sovelluksessa', language)}</li>
      </ol>
      <h4>iPhone</h4>
      <p><strong>{t('Ennen GPX-tiedoston lataamista puhelimeesi, sinulla on oltava GPX-tiedostojen avaamiseen tarkoitettu sovellus muutoin reittitiedosto ei suostu latautumaan. Lataa siis sovellus ennen yksittäisten GPX-reittitiedostojen lataamista.', language)}</strong></p>
      {t('Ilmainen vaihtoehto', language)}
      <ol>
        <li><a target='_blank' href='https://itunes.apple.com/fi/app/karttaselain-maastokartta/id366054373'>{t('Lataa sovelluskaupasta sovellus nimeltä Karttaselain - Maastokarta', language)} &raquo;</a></li>
        <li>{t('Klikkaa Nuuksioon.fi -palvelun “Lataa GPX-tiedosto””', language)}</li>
        <li>{t('Avaa tiedosto, selain ehdottanee automaattisesti äsken ladattua sovellusta. Jos ei, klikkaa "Lisää..." ja valitse sovellus.', language)}</li>
      </ol>
    </div>
  </section>
);

