
export const hideModal = () => ({ modalDisplay: 'none' });

export const showModal = (e) => {
  e.preventDefault();
  return { modalDisplay: 'block' };
};

export const hideGiftCard = () => ({ giftGardHidden: true });

export const showGiftCard = () => ({ giftGardHidden: false });
