
import { h } from 'hyperapp';
import LogoEU from '../../../images/logos/logo_EU.png';
import LogoEUEN from '../../../images/logos/logo_EU_en.png';
import LogoVipuVoimaa from '../../../images/logos/logo_VipuVoimaa.png';
import LogoVipuVoimaaEN from '../../../images/logos/logo_VipuVoimaa_en.png';
import LogoUudenmaanLiitto from '../../../images/logos/logo_UudenmaanLiitto.png';
import LogoUudenmaanLiittoEN from '../../../images/logos/logo_UudenmaanLiitto_en.png';
import Logo6Aika from '../../../images/logos/logo_6aika.png';
import LogoPerilleAsti from '../../../images/logos/logo_PerilleAsti.png';
import LogoPerilleAstiEN from '../../../images/logos/logo_PerilleAsti_en.png';
import LogoEspoo from '../../../images/logos/logo_Espoo.png';
import LogoVisitEspoo from '../../../images/logos/logo_VisitEspoo.png';
import LogoMetsahallitus from '../../../images/logos/logo_Metsahallitus_green.png';

import t from '../../utils/translate';

const LogosEn = () => (
  <div class='partners-list'>
    <PartnerItem image={LogoEUEN} link='http://www.rakennerahastot.fi/web/en' />
    <PartnerItem image={LogoVipuVoimaaEN} link='http://www.rakennerahastot.fi/web/en' />
    <PartnerItem image={LogoUudenmaanLiittoEN} link='https://uudenmaanliitto.fi/en' />
    <PartnerItem image={Logo6Aika} link='https://6aika.fi/in-english/' />
    <PartnerItem image={LogoPerilleAstiEN} link='https://www.visitespoo.fi/fi/perille-asti-hanke/' />
    <PartnerItem image={LogoEspoo} link='https://www.espoo.fi/en-US' />
    <PartnerItem image={LogoVisitEspoo} link='https://www.visitespoo.fi/en/' />
    <PartnerItem image={LogoMetsahallitus} link='https://www.metsa.fi/web/en'/>
  </div>
);

const Logos = () => ({ language }) => {
  if (language === 'en') {
    return (
      <LogosEn />
    );
  }

  return (
    <div class='partners-list'>
      <PartnerItem image={LogoEU} link='http://rakennerahastot.fi/' />
      <PartnerItem image={LogoVipuVoimaa} link='http://rakennerahastot.fi/' />
      <PartnerItem image={LogoUudenmaanLiitto} link='https://uudenmaanliitto.fi/' />
      <PartnerItem image={Logo6Aika} link='https://6aika.fi/' />
      <PartnerItem image={LogoPerilleAsti} link='https://www.visitespoo.fi/fi/perille-asti-hanke/' />
      <PartnerItem image={LogoEspoo} link='https://www.espoo.fi/' />
      <PartnerItem image={LogoVisitEspoo} link='https://visitespoo.fi/' />
      <PartnerItem image={LogoMetsahallitus} link='https://www.metsa.fi' />
    </div>
  );
};

export default () => ({ language }) => (
  <section class='partners'>
    <h4>{t('Yhteistyössä', language)}</h4>
    <Logos />
  </section>
);

const PartnerItem = ({ image, link }) => (
  <a class='partner-item' target='_blank' href={link}>
    <img alt='Logo' src={image} />
  </a>
);

