import axios from 'axios';
import { request } from 'graphql-request';

import t from '../utils/translate';
import utils from '../views/content/utils';
import storage from '../utils/local-storage';

const urlRoute = 'https://api.tietotemput.fi/nuuksioon/wp-json/veikko/v2/route/';
const digiTransitRoutingApiUrl = 'https://api.digitransit.fi/routing/v1/routers/hsl/index/graphql';
const reittiopasUrl = 'https://www.reittiopas.fi/reitti/';

export const getRoute = id => (state, { selectRoute }) => {
  axios.get(`${urlRoute}${id}`)
    .then(res => selectRoute(res.data))
    .catch(error => console.log(error));
};

export const selectRoute = selectedRoute => (state, actions) => {
  const offset = Math.max(0, state.routes.findIndex(r => r.id === selectedRoute.id));

  storage.set(selectedRoute, 'selectedRoute');
  storage.set(offset, 'offset');
  try {
    actions.location.go(`/${state.language}/${selectedRoute.slug[state.language]}`);
  } catch (error) {
    console.log(error);
  }
  return { selectedRoute, offset };
};

export const getRoutePlan = ({ data, duration, id }) =>
  (state, { routePlanHandler, setTransport }) => {
    if (!utils.isNumeric(duration)) {
      const response = '';
      setTransport({ response, id });
      return;
    }

    const { stops } = data;
    const { fromLat, fromLng, params } = state;

    if (!fromLat || !fromLng) {
      const response = 'no-address';
      setTransport({ response, id });
      return;
    }

    const routeParams = {
      id,
      startPointLat: stops.start.lat,
      startPointLng: stops.start.lng,
      endPointLat: stops.end.lat,
      endPointLng: stops.end.lng,
      fromLat,
      fromLng,
      duration: 3600 * duration,
      timestamp: params.timestamp * 1000,
      startBusStopName: stops.start.name,
      endBusStopName: stops.end.name,
    };

    routePlanHandler(routeParams);
  };

export const routePlanHandler = params => (state, { setTransport }) => {
  const leadingZero = (num) => {
    const number = num < 10 ? `0${num}`.slice(-2) : num;
    return number;
  };
  const formatDate = (date) => {
    const dateFormated = [date.getFullYear(), (date.getMonth() + 1), date.getDate()].map(leadingZero).join('-');
    return dateFormated;
  };
  const formatTime = (date) => {
    const timeFormated = [date.getHours(), date.getMinutes(), date.getSeconds()].map(leadingZero).join(':');
    return timeFormated;
  };
  const generateReittiOpasUrl = ({
    fromLat,
    fromLng,
    toLat,
    toLng,
    timestamp,
    startLabel,
    endLabel,
  }) => `${reittiopasUrl}${startLabel}::${fromLat},${fromLng}/${endLabel}::${toLat},${toLng}?time=${timestamp / 1000}`;

  const parseItinaryData = (data, dep) => {
    const departure = dep;
    let d = {};

    const { itineraries } = data.plan;

    if (itineraries.length) {
      const itinary = itineraries[0];
      if (typeof itinary.legs !== 'undefined') {
        const { legs } = itinary;
        const arriveStartTime = legs[0].startTime;
        legs.filter(leg => leg.mode === 'BUS')
          .map((leg) => {
            const stop = departure ? leg.from.stop : leg.to.stop;
            const { startTime, endTime } = leg;
            return { stop, startTime, endTime };
          });

        d = departure ?
          legs[Object.keys(legs)[0]] :
          legs[Object.keys(legs)[Object.keys(legs).length - 1]];

        d.startTime = !departure ? arriveStartTime : d.startTime;

        d.duration = itinary.duration;
      }
    }

    return d;
  };
  const generateGraphQLQuery = ({
    fromLat,
    fromLng,
    toLat,
    toLng,
    date,
    time,
  }) => {
    const query = `{
    plan(
        from: { lat: ${fromLat}, lon: ${fromLng} }, 
        to: {lat: ${toLat}, lon: ${toLng}}, 
        date: "${date}"
        time: "${time}"
        numItineraries: 3
        )
        {
        itineraries {
            walkDistance
            duration
            legs {
                startTime
                route {
                    id
                }
                endTime
                mode
                duration
                distance
                transitLeg
                from {
                    lat
                    lon
                    name
                    stop {
                        code
                        name
                    }
                }
                to {
                    lat
                    lon
                    name
                    stop {
                        code
                        name
                    }
                }
            }
        }
    }
    }`;

    return query;
  };

  let routeDuration = 0;

  let { fromLat } = 0;
  let { fromLng } = 0;
  let { toLat } = 0;
  let { toLng } = 0;
  let { startPointCoordLat } = 0;
  let { startPointCoordLng } = 0;
  let { endPointCoordLat } = 0;
  let { endPointCoordLng } = 0;
  let timestamp = new Date().getTime();
  let startLabel = state.selectedAddress.name || t('Lähtöpaikka');
  let endLabel = t('Määränpää');
  let startBusStopName = t('Määränpää');
  let endBusStopName = t('Lähtöpaikka');

  if (params) {
    fromLat = params.fromLat || fromLat;
    fromLng = params.fromLng || fromLng;

    startPointCoordLat = params.startPointLat || startPointCoordLat;
    startPointCoordLng = params.startPointLng || startPointCoordLng;
    endPointCoordLat = params.endPointLat || startPointCoordLng;
    endPointCoordLng = params.endPointLng || startPointCoordLat;

    routeDuration = params.duration || routeDuration;

    timestamp = parseInt(params.timestamp, 10) || timestamp;

    startBusStopName = params.startBusStopName || startBusStopName;
    endBusStopName = params.endBusStopName || endBusStopName;
    endLabel = startBusStopName;
  }
  let dateObj = new Date(timestamp);

  let date = formatDate(dateObj);
  let time = formatTime(dateObj);

  let response = {
    arrive: {},
    departure: {},
  };

  // Arrive
  toLat = startPointCoordLat;
  toLng = startPointCoordLng;
  let query = generateGraphQLQuery({
    fromLat,
    fromLng,
    toLat,
    toLng,
    date,
    time,
  });
  request(digiTransitRoutingApiUrl, query)
    .then((data) => {
      response.arrive.url = generateReittiOpasUrl({
        fromLat,
        fromLng,
        toLat,
        toLng,
        timestamp,
        startLabel,
        endLabel,
      });

      response.arrive.plan = parseItinaryData(data, false);

      // Departure
      const arriveDuration = response.arrive.plan.duration || 0;

      timestamp = parseInt(timestamp, 10)
      + (parseInt(arriveDuration, 10) * 1000)
      + (parseInt(routeDuration, 10) * 1000);
      dateObj = new Date(timestamp);
      date = formatDate(dateObj);
      time = formatTime(dateObj);

      toLat = fromLat;
      toLng = fromLng;
      fromLat = endPointCoordLat;
      fromLng = endPointCoordLng;
      query = generateGraphQLQuery({
        fromLat,
        fromLng,
        toLat,
        toLng,
        date,
        time,
      });
      request(digiTransitRoutingApiUrl, query)
        .then((d) => {
          startLabel = endBusStopName;
          endLabel = state.selectedAddress.name || t('Määränpää');
          response.departure.url = generateReittiOpasUrl({
            fromLat,
            fromLng,
            toLat,
            toLng,
            timestamp,
            startLabel,
            endLabel,
          });

          response.departure.plan = parseItinaryData(d, true);

          response.routeFullTime =
          response.arrive.plan.duration + response.departure.plan.duration;

          setTransport({ response, id: params.id });
        })
        .catch((error) => {
          response = 'error';
          setTransport({ response, id: params.id, error });
        });
    })
    .catch((error) => {
      response = 'error';
      setTransport({ response, id: params.id, error });
    });
};

export const setTransport = transport => ({ routes }) => {
  if (!transport || !transport.response) {
    return null;
  }
  const { response, id } = transport;

  const i = routes.findIndex(r => r.id === id);

  const newRoutes = routes;

  newRoutes[i].transport = response;

  storage.set(newRoutes, 'routes');

  return { routes: newRoutes };
};

export const nextRoute = () => ({ offset, routes }, actions) => {
  const newOffset = Math.min(routes.length - 1, offset + 1);

  const selectedRoute = routes[newOffset];

  actions.selectRoute(selectedRoute);

  return { offset: newOffset, selectedRoute };
};

export const prevRoute = () => ({ offset, routes }, actions) => {
  const newOffset = Math.max(0, offset - 1);

  const selectedRoute = routes[newOffset];

  actions.selectRoute(selectedRoute);

  return { offset: newOffset, selectedRoute };
};
