import { h } from 'hyperapp';
import PalauteBadgeImg from '../../images/palaute-badge.png';
import PalauteBadgeImgEn from '../../images/palaute-badge_en.png';

import t from '../utils/translate';

export default () => ({ language }) => (
  <div class='feedback-btn'>
    <a href={language === 'en' ? 'https://forms.gle/42V4FQCC8gPyudXL8' : 'https://docs.google.com/forms/d/e/1FAIpQLSdwVd9Ad5LTQCdFgL3iS6cut3vcUi0h6B3iCbAb5Vt5bn04hg/viewform'} target='_blank'>
      <div class='text-block'>{t('Anna palautetta', language)}</div>
      <img src={language === 'en' ? PalauteBadgeImgEn : PalauteBadgeImg} />
    </a>
  </div>
);
