import { location } from '@hyperapp/router';

import storage from '../utils/local-storage';

let fromSingleUrl = false;

const paths = location.state.pathname.substr(1).split('/');
const language = paths[0];
if (paths.length > 1 && paths[1]) {
  fromSingleUrl = true;
}


const defaultState = {
  fromSingleUrl,
  location: location.state,
  reittiopasUrl: 'https://www.reittiopas.fi/',
  routes: storage.get('routes') || [],
  language: language && (language === 'en' || language === 'fi') ? language : 'fi',
  params: {
    filters: '',
    timestamp: '',
  },
  addresses: [],
  addressListOpen: false,
  selectedAddress: null,
  selectedRoute: null,
  map: null,
  mapMarkers: [],
  searchCount: 0,
  ajaxLoading: false,
  dropdownOpen: false,
  pagination: storage.get('pagination') || {
    offset: 0,
    total: 0,
  },
  offset: storage.get('offset') || 0,
  total: storage.get('total') || 0,
  fromLat: null,
  fromLng: null,
  transport: null,
  markers: false,
  someImages: [],
  modalDisplay: 'none',

  giftGardHidden: true,
};

export default defaultState;
