import L from 'leaflet';
import 'leaflet-gpx';

import t from '../utils/translate';

import markerIcon from '../../images/icons/marker-icon.png';

const poiServiceTypes = [
  { id: 'information_board', label: 'Opastustaulu' },
  { id: 'parking', label: 'Pysäköintialue' },
  { id: 'campfire_place', label: 'Tulentekopaikka' },
  { id: 'cooking_shelter', label: 'Keittokatos' },
  { id: 'tent_site', label: 'Telttailupaikka' },
  { id: 'reservable_tent_site', label: 'Varattava telttailupaikka' },
  { id: 'leantto_shelter', label: 'Laavu' },
  { id: 'reservable_lapp_hut', label: 'Varauskota' },
  { id: 'reservable_sauna', label: 'Varaussauna' },
  { id: 'rental_cabin', label: 'Vuokrakämppä' },
  { id: 'drinking_water', label: 'Vesipiste' },
  { id: 'swimming_place', label: 'Uimapaikka' },
  { id: 'hotel', label: 'Hotelli / Majoitus' },
  { id: 'store', label: 'Kauppa' },
  { id: 'recycling', label: 'Jätteiden lajittelu' },
  { id: 'toilet', label: 'WC / käymälä' },
];

const translateServiceType = (services, language) => {
  if (!services) {
    return '';
  }

  const types = services.split(',')
    .map(service => poiServiceTypes
      .find(({ id }) => id === service).label);

  return types.map(label => t(label, language)).join(', ');
};

export const createMap = ({
  mapid,
  gpx,
  markers,
  icons,
  stops,
  waypoints,
}) => (state, { initMap, drawMap }) => {
  initMap(mapid);
  drawMap({
    gpx,
    markers,
    icons,
    stops,
    waypoints,
  });
};

export const drawMap = ({
  gpx,
  markers,
  stops,
  waypoints,
}) => ({ map, language }) => {
  const poiMarkers = [];

  new L.GPX(gpx, {
    async: true,
    marker_options: {
      startIconUrl: null,
      endIconUrl: null,
      shadowUrl: null,
    },
  }).on('loaded', (e) => {
    markers.forEach(({
      coordinates,
      name,
      icon,
      type,
      services,
    }, i) => {
      const { lat, lng } = coordinates;

      if (lat === '' || lng === '') {
        return;
      }

      let text = `<b>${name[language]}</b><br />`;

      if (type === 'service') {
        text += translateServiceType(services.join(','), language);
      }

      const poiMarker = L.marker([lat, lng], { icon: L.icon(icon) })
        .addTo(map)
        .bindPopup(text, {
          className: `marker-${i}`,
          autoClose: true,
          closeOnClick: false,
        }).openPopup();

      poiMarkers.push(poiMarker);
    });

    waypoints.forEach(({ coordinates, text }, i) => {
      const { lat, lng } = coordinates;

      if (lat === '' || lng === '') {
        return;
      }

      const txt = `<b>${text[language]}</b>`;
      const wpIcon = L.divIcon({ iconSize: [30, 30], html: `<span class="number">${i + 2}</span>`, className: `waypoint-icon waypoint-icon-${i}` });

      L.marker([lat, lng], { icon: wpIcon })
        .addTo(map)
        .bindPopup(txt, {
          className: `waypoint-marker-${i}`,
          autoClose: false,
          closeOnClick: false,
        }).openPopup();
    });

    // Start Stop
    Object.keys(stops).forEach((key) => {
      const { lat, lng, name } = stops[key];
      if (lat !== '' || lng !== '') {
        const stopIcon = L.divIcon({
          iconSize: [30, 30],
          shadowSize: [30, 30],
          iconAnchor: [15, 15],
          shadowAnchor: [15, 15],
          popupAnchor: [0, 0],
          html: `<span class="number">${key === 'start' ? 1 : waypoints.length + 2}</span>`,
          className: `waypoint-icon bus-stop-icon bus-stop-icon-${key}`,
        });

        L.marker([lat, lng], { icon: stopIcon })
          .bindPopup(name)
          .addTo(map);
      }
    });
    map.fitBounds(e.target.getBounds());
  }).addTo(map);

  // Geolocation

  navigator.geolocation.getCurrentPosition((location) => {
    const geolatlng = new L.LatLng(location.coords.latitude, location.coords.longitude);

    L.marker(geolatlng, {
      icon: L.icon({
        iconUrl: markerIcon,
        iconSize: [14, 14],
      }),
    }).addTo(map);
  });
};

export const initMap = (mapid) => {
  const map = L.map(mapid, {
    scrollWheelZoom: false,
  });
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: 'Map data &copy; <a href="http://www.osm.org">OpenStreetMap</a>',
  }).addTo(map);

  return { map };
};
