import axios from 'axios';
import storage from '../utils/local-storage';

const url = 'https://api.tietotemput.fi/nuuksioon/wp-json/veikko/v2/routes/';
// const url = 'http://veikko.tietotemput.fi/wp-json/veikko/v2/routes/';

export const search = () => (state, {
  setRoutes,
  emptyResults,
  searchCounter,
  setAjaxLoading,
  getRoutePlan,
  setFromSingleUrl,
}) => {
  emptyResults();

  searchCounter(1);
  setAjaxLoading(true);

  const params = Object.keys(state.params)
    .filter(key => state.params[key].length > 0)
    .map(key => `${key}=${state.params[key]}`)
    .join('&');

  axios.get(`${url}?${params}`)
    .then((res) => {
      if (state.fromSingleUrl) {
        const slug = state.location.pathname.replace(`/${state.language}/`, '');
        res.data = res.data.sort((a) => {
          if (a.slug[state.language] === decodeURIComponent(slug)) return -1;
          return 0;
        });
        setFromSingleUrl(false);
      }

      res.data = res.data.filter(a => a.name[state.language] !== '');
      setRoutes(res.data);
      storage.set(res.data, 'routes');
      return res.data;
    })
    .then(routes => routes.forEach(route => getRoutePlan(route)))
    .then(() => {
      setAjaxLoading(false);
    })
    .catch(error => console.log(error));
};

export const searchCounter = value => state => ({ searchCount: state.searchCount + value });
export const setAjaxLoading = status => () => ({ ajaxLoading: status });

export const emptyResults = () => (state, { setRoutes }) => {
  setRoutes([]);
  storage.remove('routes');
  storage.remove('selectedRoute');
  storage.remove('pagination');
  storage.remove('offset');
  storage.remove('total');
};

export const setRoutes = routes => (state, { selectRoute }) => {
  let [selectedRoute] = routes;

  if (routes.length > 0) {
    selectRoute(routes[0]);
  } else {
    selectedRoute = null;
  }

  storage.set({ offset: 0, total: routes.length }, 'pagination');

  return {
    routes,
    pagination: { offset: 0, total: routes.length },
    offset: 0,
    selectedRoute,
  };
};

export const setFromSingleUrl = fromSingleUrl => () => ({ fromSingleUrl });
