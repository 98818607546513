import { h } from 'hyperapp';
import { Link } from '@hyperapp/router';

import Map from './map';
import utils from './utils';

import { LanguageSelector } from '../header';

import t from '../../utils/translate';

export default () => ({ routes, pagination }) => {
  const resStyle = {
    width: `${pagination.total * 285}px`,
    marginLeft: `${pagination.offset * -285}px`,
  };

  return (
    <section class='main-results'>
      <MobileRoutesBlock />
      <div class='routes-cont'>
        <div class='routes-results' style={resStyle}>
          {routes.map(route => (
            <Result route={route} />
          ))}
        </div>
      </div>
      <div id='map-cont'>
        {routes.map(route => (
          <Map route={route}/>
        ))}
      </div>
      <ButtonsBlock />
      <MobileButtonsBlock />
    </section>
  );
};

const MobileRoutesBlock = () => ({ offset, routes, language }, { emptyResults }) => {
  if (routes.length < 1) {
    return null;
  }

  return (
    <div class='mobile-routes'>
      <div class='width-holder'>
        <h1>Nuuksioon.fi
          <LanguageSelector />
        </h1>
        <div class='showing-results-no'>
          {t('Näytetään reitti', language)} <span>{offset + 1} / {routes.length}</span>
        </div>
        <div class='empty-results-btn'>
          <Link to={`/${language}`} onclick={() => emptyResults()}>{t('Muuta hakuasetuksia', language)}</Link>
        </div>
        <div class='reset-btn'>
          <Link to={`/${language}`} onclick={() => emptyResults()}>{t('Alkuun', language)}</Link>
        </div>
      </div>
    </div>
  );
};

const MobileButtonsBlock = () => ({ offset, routes }, actions) => {
  const total = routes.length;

  if (total < 2) {
    return null;
  }

  let prevClass = 'button prev';
  let nextClass = 'button next';

  if (offset < 1) {
    prevClass += ' disabled';
  }

  if (offset > total - 2) {
    nextClass += ' disabled';
  }

  const { prevRoute, nextRoute } = actions;

  return (
    <div class='btns btns-mobile'>
      <button class={prevClass} onclick={() => prevRoute()}>
        «
      </button>
      <button class={nextClass} onclick={() => nextRoute()}>
        »
      </button>
    </div>
  );
};

const ButtonsBlock = () => (state, actions) => {
  const { offset, total } = state.pagination;

  if (total < 5) {
    return null;
  }

  let prevClass = 'button prev';
  let nextClass = 'button next';

  if (offset < 1) {
    prevClass += ' disabled';
  } else if (offset > total - 5) {
    nextClass += ' disabled';
  }

  const { prev, next } = actions.pagination;

  // Add navigation via keyboard
  document.onkeydown = (e) => {
    switch (e.which || e.keyCode) {
      case 37:
        prev();
        e.preventDefault();
        break;

      case 39:
        next();
        e.preventDefault();
        break;

      default: break;
    }
  };

  return (
    <div class='btns'>
      <button class={prevClass} onclick={() => prev()}>
        «
      </button>
      <button class={nextClass} onclick={() => next()}>
        »
      </button>
    </div>
  );
};

const Result = ({ route }) => ({ selectedRoute, language }, { selectRoute }) => {
  const {
    id,
    name,
    difficulty,
    duration,
    length,
  } = route;

  const diffTranslated = t(utils.translateDifficulty(difficulty), language);
  const description = route.description_short;

  let resClass = 'route';

  if (selectedRoute && selectedRoute.id === id) {
    resClass += ' active';
  }
  if (!route.transport) {
    resClass += ' no-transport';
  } else if (route.transport === 'error') {
    resClass += ' transport-error';
  }

  const lenClass = route.is_biking_trail ? 'icon bicycle' : 'icon walk';

  resClass += ` ${difficulty}`;

  return (
    <div class={resClass} onclick={() => selectRoute(route)}>
      <div class='loader'>{t('Haetaan julkisen liikenteen yhteyksiä', language)}...</div>
      <div class='route-info route-info-top'>
        <div class='difficulty'>
          <span title={difficulty} class={`icon ball-icon ${difficulty}`}></span> {diffTranslated}
        </div>
      </div>
      <div class='name'>
        {name[language]}
      </div>
      <div class='description'>
        {description[language]}
      </div>
      <div class='route-info route-info-bottom'>
        <div class='duration'>
          <span class='icon time'></span> {duration} h
        </div>
        <div class='length'>
          <span class={lenClass}></span>  {length} km
        </div>
      </div>
      <div class='more-btn hidden'>
        <a class='button' href='#'>{t('Tutustu', language)}</a>
      </div>
    </div>
  );
};
