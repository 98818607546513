export const loadLanguage = () => {
  const paths = window.location.pathname.substr(1).split('/');
  const language = paths[0];

  if (language && (language === 'en' || language === 'sv')) {
    return { language };
  }

  return false;
};
export const setLanguage = language => (state, actions) => {
  const slug = state.selectedRoute ? state.selectedRoute.slug[state.language] : '';
  actions.location.go(`/${language}/${slug}`);

  // Filter routes
  actions.emptyResults();

  return { language };
};
