import axios from 'axios';

export const selectAddress = address => (state, { setSelectedAddress }) => {
  const input = document.getElementById('address-input');
  input.value = address.name;
  setSelectedAddress(address);
  return {
    addressListOpen: false,
    fromLat: address.coordinates[1],
    fromLng: address.coordinates[0],
  };
};

export const searchAddressOnFocus = target => (state, { searchAddress, setAddressListOpen }) => {
  const { value } = target;
  if (value !== '' && !state.addresses.length) {
    searchAddress(target);
  } else {
    setAddressListOpen(true);
  }
};

export const searchAddress = ({ value }) => (state, actions) => {
  actions.setSelectedAddress(null);

  if (value.length > 2) {
    const queryParams = {
      text: value,
      lang: state.language,
      layers: 'address,venue,street',
      'boundary.circle.lat': 60.312345,
      'boundary.circle.lon': 24.483482,
      'boundary.circle.radius': 60,
      'focus.point.lat': 60.17,
      'focus.point.lon': 24.94,
    };

    const esc = encodeURIComponent;
    const query = Object.keys(queryParams)
      .map(k => `${esc(k)}=${esc(queryParams[k])}`)
      .join('&');

    axios.get(`https://api.digitransit.fi/geocoding/v1/search?${query}`)
      .then((res) => {
        const { features } = res.data;
        if (features) {
          actions.setAddressListOpen(true);

          actions.setAddresses(features.map(feature => ({
            name: feature.properties.label,
            coordinates: feature.geometry.coordinates,
          })));
        }
      })
      .catch(error => console.log(error));
  } else {
    actions.setAddresses([]);
  }
};


export const setAddresses = addresses => ({ addresses });
export const setAddressListOpen = open => ({ addressListOpen: open });
export const setSelectedAddress = selectedAddress => ({ selectedAddress });
