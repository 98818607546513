import converter from '../showdown';
import t from '../../utils/translate';

export default {
  m2km: length => (length / 1000).toFixed(1),

  formatTime: (date) => {
    if (date === undefined) {
      return null;
    }

    const d = new Date(date);
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const minutesWithZero = minutes > 9 ? minutes : `0${minutes}`;

    return `${hours}:${minutesWithZero}`;
  },

  isNumeric: n => !isNaN(parseFloat(n)) && isFinite(n),

  formatTime2unix: date => parseInt((new Date(date).getTime() / 1000).toFixed(0), 10),

  translateDifficulty: (key) => {
    const diffs = [
      { id: 'hard', label: 'Vaativa' },
      { id: 'average', label: 'Keskivaativa' },
      { id: 'easy', label: 'Helppo' },
    ];

    const res = diffs.find(({ id }) => id === key);

    return res ? res.label : key;
  },

  translateServiceType: (services, language) => {
    const poiServiceTypes = [
      { id: 'information_board', label: 'Opastustaulu' },
      { id: 'parking', label: 'Pysäköintialue' },
      { id: 'campfire_place', label: 'Tulentekopaikka' },
      { id: 'cooking_shelter', label: 'Keittokatos' },
      { id: 'tent_site', label: 'Telttailupaikka' },
      { id: 'reservable_tent_site', label: 'Varattava telttailupaikka' },
      { id: 'leantto_shelter', label: 'Laavu' },
      { id: 'reservable_lapp_hut', label: 'Varauskota' },
      { id: 'reservable_sauna', label: 'Varaussauna' },
      { id: 'rental_cabin', label: 'Vuokrakämppä' },
      { id: 'drinking_water', label: 'Vesipiste' },
      { id: 'swimming_place', label: 'Uimapaikka' },
      { id: 'hotel', label: 'Hotelli / Majoitus' },
      { id: 'store', label: 'Kauppa' },
      { id: 'recycling', label: 'Jätteiden lajittelu' },
      { id: 'toilet', label: 'WC / käymälä' },
    ];

    if (!services) {
      return '';
    }

    const types = services.split(',')
      .map(service => poiServiceTypes
        .find(({ id }) => id === service).label);

    return types.map(label => t(label, language)).join(', ');
  },
  htmlify: (selector) => {
    console.log(selector);
    // const $ = document.querySelector(selector);
    // $.innerHTML = $.textContent;
  },
  parseMarkdown: (inputText) => {
    let replacedText = '';

    replacedText = converter.makeHtml(inputText);

    return replacedText;
  },
};
