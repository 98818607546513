import axios from 'axios';

export const getSomeImages = () => (state, { setSomeImages }) => {
  const accessToken = '3437808587.3a81a9f.632b4e2c86594c689fe8c2a66c84f379';
  const tagName = 'nuuksio';

  const someUrl = `https://api.instagram.com/v1/tags/${tagName}/media/recent?count=5&access_token=${accessToken}`;
  axios.get(someUrl)
    .then(res => setSomeImages(res.data.data))
    .catch(error => console.log(error));
};

export const setSomeImages = someImages => ({ someImages });
